import React from 'react';
import {AppRouter} from './router/AppRouter';

export const PortfolioApp = () => {
    return (
        <>
        <AppRouter/>

        </>
)}
